import { useContext, useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ApplicationContext } from "./contexts/ApplicationContext.js";
import { AuthContext } from "./contexts/AuthContext.js";
import { ModalContext } from "./contexts/ModalContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";

import styles from "../styles/Modal.module.scss";

import DocumentWithPenIcon from "../img/document-with-pen.svg";
import DocumentWithLockIcon from "../img/document-with-lock.svg";
import ShushIcon from "../img/shush.svg";

export default function Modal({ prompt, response }) {
  const { setUser } = useContext(AuthContext);
  const { displayModal, setDisplayModal } = useContext(ModalContext);
  const { addMessageBanner } = useContext(MessageBannerContext);
  const { applicationConfig } = useContext(ApplicationContext);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [checkboxWarning, setCheckboxWarning] = useState("");
  const [markdownContent, setMarkdownContent] = useState("");

  const modalConfig = applicationConfig.modals.find(
    (m) => m.name === displayModal
  );

  const handleAction = async (e) => {
    if (e.target.name === "AcceptInitialPolicy") {
      if (modals[displayModal].useCheckbox) {
        if (!checkboxChecked) {
          setCheckboxWarning(modals[displayModal].checkboxWarning);
          return;
        }
      }

      const acceptInitialPolicy = async () => {
        try {
          const response = await fetch("/api/auth/accept-initial-policy", {
            method: "PUT",
            credentials: "include",
          });

          if (!response.ok) {
            throw new Error("Failed to accept initial policy");
          }
        } catch (error) {
          console.error("Error accepting initial policy:", error);
        } finally {
          setUser((prev) => ({
            ...prev,
            initialPolicy: 1,
          }));
          setDisplayModal("");
        }
      };

      acceptInitialPolicy();
    }

    if (e.target.name === "ExitModal") setDisplayModal("");
    if (e.target.name === "SendNamedEntities") setDisplayModal("");
    if (e.target.name === "EditNamedEntities") setDisplayModal("");

    if (e.target.name === "AcceptHistoryConsent") {
      const acceptHistoryConsent = async () => {
        try {
          const response = await fetch("/api/conversations/activate-history", {
            method: "PUT",
            credentials: "include",
          });

          const contentType = response.headers.get("Content-Type");

          if (contentType && contentType.includes("application/json")) {
            const responseBody = await response.json();
            if (response.ok) {
              setUser((prev) => ({
                ...prev,
                conversations: 1,
              }));
              addMessageBanner({
                position: "topMiddle",
                type: "success",
                text: responseBody.message,
              });
            } else {
              addMessageBanner({
                position: "topMiddle",
                type: "failure",
                text: responseBody.message,
              });
            }
          } else if (!response.ok) {
            throw new Error("Non-JSON response, status: " + response.status);
          }
        } catch (error) {
          console.error("Error activating conversation history:", error);
          addMessageBanner({
            position: "topMiddle",
            type: "failure",
            text: "Ett oväntat fel uppstod. Vänligen försök igen senare.",
          });
        } finally {
          setDisplayModal("");
        }
      };

      acceptHistoryConsent();
    }

    if (e.target.name === "DeactivateConversationHistory") {
      const deactivateConversationHistory = async () => {
        try {
          const response = await fetch(
            "/api/conversations/deactivate-history",
            {
              method: "PUT",
              credentials: "include",
            }
          );

          const contentType = response.headers.get("Content-Type");

          if (contentType && contentType.includes("application/json")) {
            const responseBody = await response.json();
            if (response.ok) {
              setUser((prev) => ({
                ...prev,
                conversations: 0,
              }));
              addMessageBanner({
                position: "topMiddle",
                type: "success",
                text: responseBody.message,
              });
            } else {
              addMessageBanner({
                position: "topMiddle",
                type: "failure",
                text: responseBody.message,
              });
            }
          } else if (!response.ok) {
            throw new Error("Non-JSON response, status: " + response.status);
          }
        } catch (error) {
          console.error("Error deactivating conversation history:", error);
          addMessageBanner({
            position: "topMiddle",
            type: "failure",
            text: "Ett oväntat fel uppstod. Vänligen försök igen senare.",
          });
        } finally {
          setDisplayModal("");
        }
      };

      deactivateConversationHistory();
    }
  };

  useEffect(() => {
    const dialog = document.querySelector("dialog");

    if (dialog) {
      dialog.showModal();
    }
  }, [displayModal]);

  const modals = {
    InitialPolicy: {
      icon: {
        src: DocumentWithLockIcon,
        alt: "Policyikon",
        width: 50,
        height: 50,
      },
      title: modalConfig?.title,
      paragraphs: modalConfig?.paragraphs,
      loadMarkdownFromFile: modalConfig?.loadMarkdownFromFile,
      useLogo: modalConfig?.useLogo,
      useCheckbox: modalConfig?.useCheckbox,
      checkboxLabel: modalConfig?.checkboxLabel,
      checkboxWarning: modalConfig?.checkboxWarning,
      className: styles.initialPolicy,
      buttonContainer: {
        buttons: [
          {
            name: "AcceptInitialPolicy",
            text: "Jag förstår!",
          },
        ],
      },
    },
    HistoryConsent: {
      icon: {
        src: DocumentWithPenIcon,
        alt: "Policyikon",
        width: 50,
        height: 50,
      },
      title: "Aktivera historik",
      paragraphs: [
        "När du aktiverar historikfunktionen börjar systemet att lagra dina konversationer i tjänsten, så att du kan återuppta tidigare konversationer eller hämta tidigare skapat material.",
        "Vi krypterar självklart all lagrad konversationshistorik, så att endast du har tillgång till den.",
        "En konversation sparas i 90 dagar efter att du senast skrev i den, och du kan radera en specifik konversation när du själv önskar.",
        "Om du bestämmer dig för att du inte längre vill att dina konversationer ska sparas, kan du när som helst inaktivera historikfunktionen, och då rensas hela din konversationshistorik.",
      ],
      className: styles.historyConsent,
      buttonContainer: {
        buttons: [
          {
            name: "ExitModal",
            text: "Nej tack!",
          },
          {
            name: "AcceptHistoryConsent",
            text: "Aktivera nu.",
          },
        ],
      },
    },
    DeactivateConversationHistory: {
      icon: {
        src: DocumentWithPenIcon,
        alt: "Policyikon",
        width: 50,
        height: 50,
      },
      title: "Inaktivera historik",
      paragraphs: [
        "Genom att inaktivera historiken slutar systemet att lagra dina konversationer i tjänsten och hela din konversationshistorik kommer omgående att raderas.",
        "Du kan givetvis aktivera historikfunktionen igen om du ångrar dig i framtiden.",
        "Är du säker på att du vill inaktivera historiken och radera all din konversationshistorik?",
      ],
      className: styles.deactivateConversationHistory,
      buttonContainer: {
        buttons: [
          {
            name: "ExitModal",
            text: "Nej!",
          },
          {
            name: "DeactivateConversationHistory",
            text: "Inaktivera nu.",
          },
        ],
      },
    },
    NamedEntities: {
      icon: {
        src: ShushIcon,
        alt: "Ikon",
        width: 50,
        height: 50,
      },
      title: "Ditt meddelande har inte skickats.",
      paragraphs: [
        "Det ser ut som att det kanske kommit med lite personuppgifter i ditt meddelande. Vänligen se över nedan rödmarkerad text:",
      ],
      className: styles.namedEntities,
      buttonContainer: {
        buttons: [
          {
            name: "SendNamedEntities",
            text: "Skicka ändå.",
          },
          {
            name: "EditNamedEntities",
            text: "Editera.",
          },
        ],
      },
    },
  };

  useEffect(() => {
    if (modals[displayModal]?.loadMarkdownFromFile) {
      fetch(`/${displayModal}.md`)
        .then((response) => response.text())
        .then((text) => setMarkdownContent(text))
        .catch((error) =>
          console.error("Error fetching markdown file:", error)
        );
    }
  }, [displayModal]);

  const HighlightedText = () => {
    // Extract all highlights from the response object and flatten the array
    const highlights = Object.values(response).reduce(
      (acc, val) => acc.concat(val),
      []
    );

    if (highlights.length === 0) {
      return <span>{prompt}</span>;
    }

    // Escape special characters in strings to highlight
    const escapedStrings = highlights.map((s) =>
      s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
    );

    const regex = new RegExp(`(${escapedStrings.join("|")})`, "gi");

    // Split text into parts with or without highlight
    const parts = prompt.split(regex);

    return (
      <pre>
        {parts.filter(Boolean).map((part, i) =>
          regex.test(part) ? (
            <span key={i} className={styles.highlight}>
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
      </pre>
    );
  };

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  return (
    displayModal && (
      <dialog
        className={[styles.dialog, modals[displayModal].className].join(" ")}
      >
        <img
          src={
            modals[displayModal].useLogo
              ? "/logo.png"
              : modals[displayModal].icon.src
          }
          alt={
            modals[displayModal].useLogo
              ? "Logo"
              : modals[displayModal].icon.alt
          }
          width={
            !modals[displayModal].useLogo
              ? modals[displayModal].icon.width
              : undefined
          }
          height={
            !modals[displayModal].useLogo
              ? modals[displayModal].icon.height
              : undefined
          }
          className={modals[displayModal].useLogo ? styles.logo : ""}
        />
        <h1>{modals[displayModal].title}</h1>
        {modals[displayModal].loadMarkdownFromFile ? (
          <Markdown children={markdownContent} remarkPlugins={[remarkGfm]} />
        ) : (
          modals[displayModal].paragraphs.map((paragraph, index) => (
            <p style={{ textAlign: "center" }} key={index}>
              {paragraph}
            </p>
          ))
        )}
        {displayModal === "NamedEntities" && <HighlightedText />}
        {modals[displayModal].useCheckbox && (
          <div className={styles.checkboxContainer}>
            <label>
              <input
                type="checkbox"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
              {modals[displayModal].checkboxLabel}
            </label>
            {checkboxWarning && (
              <p className={styles.warning}>{checkboxWarning}</p>
            )}
          </div>
        )}
        <div className={styles.buttonContainer}>
          {modals[displayModal].buttonContainer.buttons.map((button, index) => (
            <button key={index} name={button.name} onClick={handleAction}>
              {button.text}
            </button>
          ))}
        </div>
      </dialog>
    )
  );
}
