import { useContext } from "react";
import PromptModeInfo from "./PromptModeInfo.js";

import styles from "../styles/ChatWelcomeMessage.module.scss";

import { ApplicationContext } from "./contexts/ApplicationContext.js";

const ChatWelcomeMessage = ({ name, mode, index }) => {
  const { applicationConfig } = useContext(ApplicationContext);

  const modeConfig = applicationConfig.modes.find((m) => m.name === mode);

  const icon = modeConfig?.img || "";
  const label = modeConfig?.label || "";

  const messages = {
    GENERAL: `Hej ${name}! Vad vill du chatta om idag?`,
    GENERAL_AZURE: `Hej ${name}! Vad vill du chatta om idag?`,
    IMAGE_GENERATION: `Hej ${name}! Vad vill du att jag ska skapa en bild på?`,
    IMAGE_GENERATION_AZURE: `Hej ${name}! Vad vill du att jag ska skapa en bild på?`,
    EMPLOYEE_HANDBOOK: `Hej ${name}! Vad undrar du?`,
  };

  return (
    <div key={index} className={styles.container}>
      <div className={styles.hej}>
        <img src={icon} alt="Hej ikon" width={40} />
        <span>{label}</span>
      </div>
      <p>{messages[mode]}</p>
      <PromptModeInfo mode={mode} />
    </div>
  );
};

export default ChatWelcomeMessage;
