import { useContext } from "react";
import { ApplicationContext } from "./contexts/ApplicationContext.js";
import styles from "../styles/PromptModeInfo.module.scss";
import infoCircle from "../img/info-circle.svg";

function parseTextWithLinks(text, hrefColor) {
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const parts = [];
  let lastIndex = 0;

  text.replace(linkRegex, (match, linkText, linkUrl, index) => {
    if (index > lastIndex) {
      parts.push(text.substring(lastIndex, index));
    }
    parts.push(
      <a
        href={linkUrl}
        key={`${linkText}-${index}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: hrefColor }}
      >
        {linkText}
      </a>
    );
    lastIndex = index + match.length;
  });

  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }

  return parts;
}

export default function PromptModeInfo({ mode }) {
  const { applicationConfig } = useContext(ApplicationContext);

  const modeConfig = applicationConfig.modes.find(
    (m) => m.name === mode
  )?.promptModeInfo;

  const obs = modeConfig?.obs || [];
  const info = modeConfig?.info || "";
  const hrefColor = modeConfig?.hrefColor;

  modeConfig.obs = modeConfig.obs.map((text) =>
    text.replace("{customer.company}", applicationConfig.customer.company)
  );

  modeConfig.info = modeConfig.info.replace(
    "{customer.company}",
    applicationConfig.customer.company
  );

  return (
    <div className={styles.component}>
      <div className={styles.info}>
        <img src={infoCircle} alt="Info ikon" width={20} />
        <span className={styles.tooltip}>{info}</span>
      </div>
      {obs.map((text, index) => (
        <p key={index}>{parseTextWithLinks(text, hrefColor)}</p>
      ))}
    </div>
  );
}
