import { createContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const MessageBannerContext = createContext(null);

export const MessageBannerProvider = ({ children }) => {
  const [messageBanners, setMessageBanners] = useState([]);

  const addMessageBanner = (newMessageBanner) => {
    const messageWithId = { ...newMessageBanner, id: uuidv4() };
    setMessageBanners((prevBanners) => [...prevBanners, messageWithId]);
  };

  const removeMessageBanner = (idToRemove) => {
    setMessageBanners((prevBanners) =>
      prevBanners.filter((banner) => banner.id !== idToRemove)
    );
  };

  const clearMessageBanners = () => {
    setMessageBanners([]);
  };

  return (
    <MessageBannerContext.Provider
      value={{
        messageBanners,
        addMessageBanner,
        removeMessageBanner,
        clearMessageBanners,
      }}
    >
      {children}
    </MessageBannerContext.Provider>
  );
};
