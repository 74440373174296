// Function to handle user query submission
export const handleAbortStream = async (uniqueChatId) => {
  try {
    const response = await fetch("/api/gpt/abort-stream", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conversation_id: uniqueChatId,
      }),
    });

    return response.ok;
  } catch (error) {
    console.log(error);
  }
};
