import { handleRenameChat } from "./handleRenameChat.js";

// Function to handle user query submission
export const handleQuery = async (
  e,
  user,
  uniqueChatId,
  bot,
  setBot,
  chat,
  setChat,
  prompt,
  setPrompt,
  addMessageBanner,
  setDisplayModal,
  prevChatLabelRef
) => {
  e.preventDefault();

  const capturedPrompt = prompt.content;
  const capturedImage = prompt.base64Image;

  setBot({
    response: "",
    sources: [
      {
        content: "",
        file: "",
      },
    ],
  });

  setChat({
    ...chat,
    history: [
      ...chat.history,
      capturedImage ? [capturedPrompt, capturedImage] : capturedPrompt,
    ],
    waiting: true,
    latestPrompt: prompt.content,
  });

  setPrompt({
    ...prompt,
    content: "",
    nerResponse: "",
  });

  // Transform chat.history to remove second element (base64 images) of nested arrays
  const sanitizedHistory = chat.history.map((entry) => {
    if (Array.isArray(entry) && entry.length > 0) {
      // Return only the first element
      return entry[0];
    }

    return entry;
  });

  if (chat.requestAiLabel && prompt.mode === "GENERAL_AZURE") {
    try {
      const response = await fetch("/api/conversations/generate-label", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          user_prompt: capturedPrompt,
          prompt_mode: prompt.mode,
          conversation_id: uniqueChatId,
          request_ai_label: chat.requestAiLabel,
        }),
      });

      if (response.ok) {
        const updateChatState = (update) => {
          return new Promise((resolve) => {
            setChat((prev) => {
              const updatedChat = { ...prev, ...update };
              resolve(updatedChat); // Resolve the promise with the updated state
              return updatedChat;
            });
          });
        };

        const responseJson = await response.json();

        updateChatState({
          label: responseJson.Label,
          requestAiLabel: false,
        }).then((updatedChat) => {
          handleRenameChat(
            updatedChat,
            prevChatLabelRef,
            uniqueChatId,
            addMessageBanner
          );
        });
      }
    } catch (error) {
      console.log("Unable to generate AI label for conversation", error);
    }
  }

  try {
    // Send user query to the server to get a response from the bot
    const response = await fetch("/api/gpt/prompt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        user_prompt: capturedPrompt,
        prompt_image: capturedImage,
        prompt_mode: prompt.mode,
        chat_history: chat.useHistory ? sanitizedHistory : [],
        chat_label: chat.label,
        conversation_id: uniqueChatId,
        stream_response: chat.streamResponse,
        ner_override: prompt.nerOverride,
      }),
    });

    if (response.status === 406) {
      // Status 406 is only received when Named Entity Recognition fires
      const data = await response.json();
      setPrompt((prev) => {
        return {
          ...prev,
          nerResponse: data,
        };
      });

      setChat((prev) => {
        return {
          ...prev,
          waiting: false,
        };
      });

      setDisplayModal("NamedEntities");

      return;
    }

    if (response.ok) {
      // Response is successful

      if (chat.streamResponse) {
        // Read the stream chunk by chunk
        const reader = response.body.getReader();
        let text = "";

        // The following function reads one chunk at a time
        const readChunk = ({ done, value }) => {
          if (done) {
            // Stream is complete
            setChat((prev) => {
              return {
                ...prev,
                history: [
                  ...chat.history,
                  capturedImage
                    ? [capturedPrompt, capturedImage]
                    : capturedPrompt,
                  text,
                ],
                waiting: false,
              };
            });

            return;
          }

          // Decode the chunk (which is a Uint8Array) and concatenate it
          const chunk = new TextDecoder().decode(value);
          text += chunk;

          setBot((prev) => {
            return {
              ...prev,
              response: prev.response + chunk,
            };
          });
          // Read the next chunk
          reader
            .read()
            .then(readChunk)
            .catch((error) => {
              console.log(error);
            });
        };

        // Start reading the stream
        reader.read().then(readChunk);
      } else {
        const data = await response.json();
        setBot({
          ...bot,
          response: data.Answer,
        });
      }
    } else {
      // If the response is not successful, display an error message
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Ett oväntat fel uppstod. Servern svarade inte med status 200. Uppdatera sidan och försök igen.",
      });
    }
  } catch (error) {
    addMessageBanner({
      position: "topMiddle",
      type: "failure",
      text: "Ett oväntat fel uppstod. Mer information finns att hitta i webbläsarens konsol.",
    });
  }

  if (!chat.streamResponse) {
    setChat((prev) => {
      return {
        ...prev,
        waiting: false,
      };
    });
  }
};
