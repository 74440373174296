export const getNewChatId = async (addMessageBanner) => {
  try {
    const response = await fetch("/api/conversations/new-id", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    if (response.ok) {
      const data = await response.json();
      return data.id;
    } else {
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Ett oväntat fel uppstod. Det gick inte att generera ett nytt chatt-ID. Uppdatera sidan och försök igen.",
      });
    }
  } catch (error) {
    console.error("Error getting new chat ID:", error);
  }
};
