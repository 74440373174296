import { useContext } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import dracula from "react-syntax-highlighter/dist/esm/styles/prism/dracula.js";

import styles from "../styles/ChatResponse.module.scss";
import "../styles/markdown.scss";

import spinner from "../img/spinner.svg";
import stopIcon from "../img/stop.svg";

import { ApplicationContext } from "./contexts/ApplicationContext.js";

const ChatResponse = ({
  content,
  mode,
  waiting,
  index,
  handleAbortStream,
  uniqueChatId,
}) => {
  const { applicationConfig } = useContext(ApplicationContext);

  const modeConfig = applicationConfig.modes.find((m) => m.name === mode);

  const icon = modeConfig?.img || "";
  const label = modeConfig?.label || "";

  const isLoading =
    waiting &&
    (!(mode === "GENERAL" || mode === "GENERAL_AZURE") ||
      ((mode === "GENERAL" || mode === "GENERAL_AZURE") &&
        content.length === 0));
  const isImageGeneration =
    mode === "IMAGE_GENERATION" || mode === "IMAGE_GENERATION_AZURE";

  return (
    <div key={index} className={styles.container}>
      <div className={styles.topper}>
        <div className={styles.hej}>
          <img src={icon} alt="Hej ikon" width={40} />
          <span>{label}</span>
        </div>
        {waiting && (mode === "GENERAL" || mode === "GENERAL_AZURE") && (
          <button onClick={() => handleAbortStream(uniqueChatId)}>
            <img src={stopIcon} alt="Stoppa" width={30} />
          </button>
        )}
      </div>
      <p
        className={styles.padded}
        style={{
          padding: isImageGeneration && !waiting && "17px 0px",
        }}
      >
        {isLoading ? (
          <img src={spinner} alt="Spinner" width={30} />
        ) : isImageGeneration ? (
          <img className={styles.image} src={content} alt="Genererad bild" />
        ) : (
          <div className="markdown-body">
            <Markdown
              remarkPlugins={[remarkGfm]}
              children={content}
              components={{
                code(props) {
                  const { children, className, node, ...rest } = props;
                  const match = /language-(\w+)/.exec(className || "");
                  return match ? (
                    <SyntaxHighlighter
                      {...rest}
                      PreTag="div"
                      children={String(children).replace(/\n$/, "")}
                      language={match[1]}
                      style={dracula}
                    />
                  ) : (
                    <code {...rest} className={className}>
                      {children}
                    </code>
                  );
                },
              }}
            />
          </div>
        )}
      </p>
    </div>
  );
};

export default ChatResponse;
