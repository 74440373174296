import styles from "../styles/ChatTopper.module.scss";

import newConversation from "../img/new-conversation.svg";

export default function ChatTopper({ doHandleNewChat }) {
  return (
    <div className={styles.component}>
      <div className={styles.newConversation}>
        <img
          src={newConversation}
          alt="Starta ny konversation"
          className={styles.newConversationImg}
          width={40}
          onClick={() => doHandleNewChat()}
        />
        <span className={styles.tooltip}>Starta ny chatt</span>
      </div>
    </div>
  );
}
