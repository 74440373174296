import { useEffect } from "react";
import { ApplicationProvider } from "./components/contexts/ApplicationContext.js";
import { AuthProvider } from "./components/contexts/AuthContext.js";
import { MessageBannerProvider } from "./components/contexts/MessageBannerContext.js";
import { ModalProvider } from "./components/contexts/ModalContext.js";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import Layout from "./components/Layout.js";
import MessageBanner from "./components/MessageBanner.js";
import Login from "./components/Login.js";
import Chat from "./components/Chat.js";
import Topper from "./components/Topper.js";

const MainContent = () => {
  return (
    <Layout>
      <MessageBanner />
      <AuthenticatedTemplate>
        <Topper />
        <Chat />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </Layout>
  );
};

export default function App() {
  useEffect(() => {
    const backgroundImageUrl = "/background.webp";

    // Set the background image style properties on the body element
    document.body.style.backgroundImage = `url(${backgroundImageUrl})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundPosition = "top";
    document.body.style.backgroundAttachment = "fixed";

    // Clean up the body styles on component unmount
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundSize = "";
      document.body.style.backgroundRepeat = "";
      document.body.style.backgroundPosition = "";
      document.body.style.backgroundAttachment = "";
    };
  }, []);

  return (
    <ApplicationProvider>
      <MessageBannerProvider>
        <ModalProvider>
          <AuthProvider>
            <MainContent />
          </AuthProvider>
        </ModalProvider>
      </MessageBannerProvider>
    </ApplicationProvider>
  );
}
