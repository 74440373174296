import { useContext } from "react";

import styles from "../styles/Topper.module.scss";

import poweredByAi from "../img/powered-by-ai.png";

import { AuthContext } from "./contexts/AuthContext.js";
import genericUserPhoto from "../img/generic-user.png";

export default function Topper() {
  const { user, handleLogout } = useContext(AuthContext);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className={styles.component}>
      <div className={styles.leftObjects}>
        <img
          src={"/logo.png"}
          alt="Logo"
          style={{ width: 236, height: "auto", cursor: "pointer" }}
          onClick={refreshPage}
        />
        <img src={poweredByAi} width={127.5} alt="Powered by AI" />
      </div>
      <div className={styles.rightObjects}>
        <div>
          <h1>{user.displayName}</h1>
          <span onClick={handleLogout}>Logga ut</span>
        </div>
        <img
          src={user.photo || genericUserPhoto}
          alt={user.displayName}
          style={{ width: 64, height: 64, opacity: user.photo ? 1 : 0.2 }}
        />
      </div>
    </div>
  );
}
