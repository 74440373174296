import { useContext } from "react";
import { ModalContext } from "./contexts/ModalContext.js";

import styles from "../styles/ChatMenuHistoryConsent.module.scss";

const ChatMenuHistoryConsent = () => {
  const { setDisplayModal } = useContext(ModalContext);

  return (
    <div className={styles.container}>
      <span className={styles.title}>History</span>
      <button
        className={styles.buttonActivate}
        onClick={() => setDisplayModal("HistoryConsent")}
      >
        Aktivera historik
      </button>
    </div>
  );
};

export default ChatMenuHistoryConsent;
