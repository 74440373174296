// Importing required modules and resources
import { useEffect, useState, useContext, useRef } from "react";

import { getNewChatId } from "./functions/Chat/getNewChatId.js";
import { handleQuery } from "./functions/Chat/handleQuery.js";
import { handleRenameChat } from "./functions/Chat/handleRenameChat.js";
import { handleNewChat } from "./functions/Chat/handleNewChat.js";
import { handleAbortStream } from "./functions/Chat/handleAbortStream.js";
import { recreateConversation } from "./functions/Chat/recreateConversation.js";

import styles from "../styles/Chat.module.scss";
import "../styles/markdown.scss";

import { AuthContext } from "./contexts/AuthContext.js";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";
import { ModalContext } from "./contexts/ModalContext.js";
import { ApplicationContext } from "./contexts/ApplicationContext.js";

import Modal from "./Modal.js";
import ChatMenu from "./ChatMenu.js";
import PromptTextarea from "./PromptTextarea.js";
import ChatMessage from "./ChatMessage.js";
import ChatResponse from "./ChatResponse.js";
import ChatWelcomeMessage from "./ChatWelcomeMessage.js";
import ChatTopper from "./ChatTopper.js";
import sendIcon from "../img/send.svg";
import chatBubbles from "../img/chat-bubbles.svg";
import addImageIcon from "../img/image-with-plus.svg";
import crossIcon from "../img/cross.svg";

const Chat = () => {
  const { user } = useContext(AuthContext);
  const { addMessageBanner } = useContext(MessageBannerContext);
  const { setDisplayModal } = useContext(ModalContext);
  const [uniqueChatId, setUniqueChatId] = useState("");
  const { applicationConfig } = useContext(ApplicationContext);

  useEffect(() => {
    // Show InitialPolicy modal if it has not been accepted before
    // or if recurringInitialPolicy is set to true in applicationConfig
    if (
      user.authorized &&
      (!user.initialPolicy ||
        applicationConfig.settings?.recurringInitialPolicy)
    ) {
      setDisplayModal("InitialPolicy");
    }
  }, [user.authorized]);

  useEffect(() => {
    // Get a new chat ID
    getNewChatId(addMessageBanner).then((id) => {
      setUniqueChatId(id);
    });
  }, []);

  const [bot, setBot] = useState({
    response: "",
    sources: [
      {
        content: "",
        file: "",
        url: "",
      },
    ],
  });

  const [prompt, setPrompt] = useState({
    content: "",
    base64Image: "",
    mode: applicationConfig.modes[0].name,
    nerResponse: "",
    nerOverride: false,
  });

  const [chat, setChat] = useState({
    label: "Namnlös chatt",
    requestAiLabel: true,
    waiting: false,
    history: [],
    useHistory: prompt.mode === "GENERAL" || prompt.mode === "GENERAL_AZURE",
    useSources: false,
    currentSourceIndex: 0,
    latestPrompt: "",
    streamResponse: true,
    isRecreating: false,
  });

  const prevChatLabelRef = useRef(chat.label); // Ref to track previous chat label

  const [autoScroll, setAutoScroll] = useState(true);
  const lastScrollTop = useRef(window.scrollY);

  const scrollToBottom = () => {
    if (autoScroll) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "auto",
      });
    }
  };

  const isAtBottom = () => {
    return (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 2
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop < lastScrollTop.current) {
        // User is scrolling up, abort auto scrolling
        setAutoScroll(false);
      } else if (isAtBottom()) {
        // User is at absolute bottom, resume auto scrolling
        setAutoScroll(true);
      }
      lastScrollTop.current = currentScrollTop;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (bot.response || (chat.useHistory && chat.history.length > 0)) {
      scrollToBottom();
    }
  }, [bot.response, chat.history, autoScroll]);

  const doHandleQuery = (e) => {
    handleQuery(
      e,
      user,
      uniqueChatId,
      bot,
      setBot,
      chat,
      setChat,
      prompt,
      setPrompt,
      addMessageBanner,
      setDisplayModal,
      prevChatLabelRef
    );
  };

  const doHandleNewChat = async (newMode) => {
    if (prompt.mode === "GENERAL" || prompt.mode === "GENERAL_AZURE") {
      await handleAbortStream(uniqueChatId);
    }

    if (!newMode) {
      newMode = prompt.mode;
    }

    handleNewChat(
      newMode,
      getNewChatId,
      addMessageBanner,
      setUniqueChatId,
      setBot,
      prompt,
      setPrompt,
      setChat,
      prevChatLabelRef
    );
  };

  const doRecreateConversation = async (conversation_id) => {
    if (prompt.mode === "GENERAL" || prompt.mode === "GENERAL_AZURE") {
      await handleAbortStream(uniqueChatId);
    }

    recreateConversation(
      conversation_id,
      setChat,
      setUniqueChatId,
      setPrompt,
      prevChatLabelRef
    );
  };

  const hiddenFileInput = useRef(null);

  const convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPrompt((prev) => {
        return {
          ...prev,
          base64Image: reader.result,
        };
      });
      console.log(reader.result);
    };

    reader.onerror = (error) => {
      console.error("Error converting file to Base64:", error);
    };
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/")) {
      convertToBase64(file);
    } else {
      console.error("File is not an image.");
    }
  };

  const handleClearImageUpload = () => {
    setPrompt((prev) => {
      return {
        ...prev,
        base64Image: "",
      };
    });
  };

  const handleEnterSubmit = (e) => {
    if (e && e.key === "Enter" && !e.shiftKey) {
      if (prompt.content && !chat.waiting) {
        doHandleQuery(e);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <ChatMenu
        prompt={prompt}
        setPrompt={setPrompt}
        setChat={setChat}
        uniqueChatId={uniqueChatId}
        doHandleNewChat={doHandleNewChat}
        doRecreateConversation={doRecreateConversation}
      />
      <div className={styles.container}>
        <Modal prompt={chat.latestPrompt} response={prompt.nerResponse} />
        <ChatTopper doHandleNewChat={doHandleNewChat} />
        <div className={styles.header}>
          {(prompt.mode === "GENERAL" || prompt.mode === "GENERAL_AZURE") && (
            <div className={styles.label}>
              <img src={chatBubbles} alt="Chat" width={20} />
              <input
                type="text"
                size={33}
                maxLength={35}
                value={chat.label}
                onChange={(e) =>
                  setChat({
                    ...chat,
                    label: e.target.value,
                  })
                }
                onBlur={() =>
                  handleRenameChat(
                    chat,
                    prevChatLabelRef,
                    uniqueChatId,
                    addMessageBanner
                  )
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.target.blur();
                  }
                }}
              />
            </div>
          )}
        </div>
        <ChatWelcomeMessage
          name={user.givenName}
          mode={prompt.mode}
          index={9999}
          isRecreating={chat.isRecreating}
        />

        {chat.useHistory &&
          chat.history &&
          chat.history.map((item, index) => {
            return index % 2 === 0 ? (
              <ChatMessage content={item} index={index} user={user} />
            ) : (
              <ChatResponse content={item} mode={prompt.mode} index={index} />
            );
          })}

        {/* This displays the latest response while it is being generated, before it, upon completeion, becomes part of the history */}
        {chat.useHistory && chat.history && chat.history.length % 2 !== 0 && (
          <ChatResponse
            content={bot.response}
            mode={prompt.mode}
            waiting={chat.waiting}
            index={chat.history.length}
            handleAbortStream={handleAbortStream}
            uniqueChatId={uniqueChatId}
          />
        )}

        {!chat.useHistory && chat.latestPrompt && (
          <ChatMessage content={chat.latestPrompt} user={user} />
        )}

        {!chat.useHistory && bot.response && (
          <ChatResponse content={bot.response} mode={prompt.mode} />
        )}

        {/* Display spinner while waiting if response is not streamed */}
        {!chat.useHistory && !bot.response && chat.waiting && (
          <ChatResponse
            content={"Genererar svar..."} // This is needed to prevent a visual bug
            mode={prompt.mode}
            waiting={chat.waiting}
          />
        )}
        {(prompt.mode === "GENERAL" || prompt.mode === "GENERAL_AZURE") && (
          <div className={styles.extras}>
            {!prompt.base64Image ? (
              <>
                <button
                  className={styles.upload}
                  onClick={() => hiddenFileInput.current.click()}
                >
                  <input
                    type="file"
                    accept="image/*"
                    ref={hiddenFileInput}
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                  />
                  <img src={addImageIcon} width={17} alt="Bifoga bild" />
                  <span>Bifoga bild</span>
                </button>
              </>
            ) : (
              <div className={styles.uploaded}>
                <img
                  className={styles.uploadedImage}
                  src={prompt.base64Image}
                  alt="Bifogad bild"
                />
                <button
                  className={styles.clear}
                  onClick={() => handleClearImageUpload()}
                >
                  <img
                    className={styles.clearIcon}
                    src={crossIcon}
                    width={20}
                    alt="Ta bort bild"
                  />
                </button>
              </div>
            )}
          </div>
        )}

        <form onSubmit={doHandleQuery}>
          <PromptTextarea
            prompt={prompt}
            setPrompt={setPrompt}
            handleEnterSubmit={handleEnterSubmit}
          />
          <button type="submit" disabled={chat.waiting}>
            <img
              src={sendIcon}
              alt="Skicka"
              width={40}
              style={{
                opacity: prompt.content && !chat.waiting ? 1 : 0.3,
              }}
            />
          </button>
        </form>
      </div>
    </div>
  );
};

export default Chat;
