import { createContext, useState, useEffect } from "react";
import { getApplicationConfig } from "../functions/config.js";

export const ApplicationContext = createContext(null);

export const ApplicationProvider = ({ children }) => {
  const [applicationConfig, setApplicationConfig] = useState({
    isLoading: true,
  });

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await getApplicationConfig();
        if (config) {
          setApplicationConfig({
            ...config,
            isLoading: false, // Set isLoading to false once the config is loaded
          });
        }
      } catch (error) {
        console.error("Failed to load application configuration:", error);
        setApplicationConfig({ isLoading: false, error });
      }
    };

    // Call the async function
    fetchConfig();
  }, []);

  return (
    <ApplicationContext.Provider
      value={{ applicationConfig, setApplicationConfig }}
    >
      {applicationConfig.isLoading ? <div>Loading...</div> : children}
    </ApplicationContext.Provider>
  );
};
