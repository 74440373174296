import styles from "../styles/ChatMessage.module.scss";

import genericUserPhoto from "../img/generic-user.png";

const ChatMessage = ({ content, index, user }) => {
  const isArrayContent = Array.isArray(content);
  const textContent = isArrayContent ? content[0] : content;
  const base64Image = isArrayContent ? content[1] : null;

  return (
    <div key={index} className={styles.container}>
      <div className={styles.user}>
        <img
          src={user.photo || genericUserPhoto}
          alt="Profilbild"
          width={40}
          style={{ opacity: user.photo ? 1 : 0.2 }}
        />
        <span>{user.displayName}</span>
      </div>
      <p>{textContent}</p>
      {base64Image && (
        <div className={styles.image}>
          <img src={base64Image} alt="Embedded content" />
        </div>
      )}
    </div>
  );
};

export default ChatMessage;
