import { useContext } from "react";
import { MessageBannerContext } from "./contexts/MessageBannerContext.js";

import styles from "../styles/MessageBanner.module.scss";

import closeSquare from "../img/close-square.svg";

export default function MessageBanner() {
  const { messageBanners, removeMessageBanner } =
    useContext(MessageBannerContext);

  const handleClose = (id) => {
    removeMessageBanner(id);
  };

  return (
    <div className={styles.container}>
      {messageBanners.map((banner) => {
        const typeClass = styles[banner.type];
        const positionClass = styles[banner.position];
        return (
          <div key={banner.id} className={`${typeClass} ${positionClass}`}>
            <div className={styles.close}>
              <img
                src={closeSquare}
                onClick={() => handleClose(banner.id)}
                width={20}
                alt="Stäng"
              />
            </div>
            {banner.text}
          </div>
        );
      })}
    </div>
  );
}
