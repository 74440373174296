export const handleRenameChat = async (
  chat,
  prevChatLabelRef,
  uniqueChatId,
  addMessageBanner
) => {
  if (
    chat.label !== prevChatLabelRef.current &&
    chat.label.length > 0 &&
    chat.label.length <= 35
  ) {
    try {
      const response = await fetch("/api/conversations/update-label", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          conversation_id: uniqueChatId,
          label: chat.label,
        }),
      });

      if (response.ok) {
        // After successfully renaming, update the ref to the new value
        prevChatLabelRef.current = chat.label;
      } else {
        addMessageBanner({
          position: "topMiddle",
          type: "failure",
          text: "Ett oväntat fel uppstod och chattens namn kunde inte uppdateras.",
        });
      }
    } catch (error) {
      console.error("Error renaming conversation:", error);
      addMessageBanner({
        position: "topMiddle",
        type: "failure",
        text: "Ett oväntat fel uppstod och chattens namn kunde inte uppdateras. Mer information finns att hitta i webbläsarens konsol.",
      });
    }
  }
};
